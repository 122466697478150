<template>
  <div class="flex h-full flex-col px-4 py-5 transition-shadow hover:shadow-[0px_0px_10.4px_0px_#4060A066] sm:px-5">
    <div class="flex flex-1 flex-col">
      <div class="flex flex-1 flex-col">
        <div class="mb-2.5 flex items-center justify-between gap-2">
          <UiTag
            v-if="isProductOnSpecialOffer"
            :tag="t('ecom_special_offer')"
            variant="specialOffer"
          />

          <ClientOnly>
            <M2AddToWishlistButton
              v-if="isLoggedIn"
              class="ml-auto"
              :product="props.product"
              variant="icon"
              :is-item-wishlisted="isItemWishlisted"
              :disabled="shouldWishlistItemBeDisabled(props.product) || wishlistPending || !props.product"
              @click:add-to-wishlist="handleAddToWishlist"
            />
          </ClientOnly>
        </div>

        <component
          :is="getLinkTag(props.product?.canonical_url ?? '', 'figure')"
          :to="productLink(props.product?.canonical_url ?? '')"
          class="group flex-1 p-1.5 pb-3.5"
        >
          <UiImage
            v-bind="$getImgProps(props.product)"
            class="aspect-magento-categories-product-image object-contain transition-transform group-hover:scale-110"
          />
          <figcaption class="mt-3.5">
            <span
              v-if="props.product?.sku"
              class="mb-1 inline-block text-xs text-grey"
            >{{ props.product.sku }}</span>
            <p
              v-if="props.product?.name"
              class="break-words leading-5 text-primary transition group-hover:underline group-hover:underline-offset-2"
            >
              {{ props.product.name }}
            </p>
          </figcaption>
        </component>
      </div>

      <div class="mt-3.5 border-t border-t-blue-450 py-3.5">
        <template v-if="isFinalPriceAssigned || isRegularPriceAssigned">
          <span class="block text-xs text-primary">{{ t('ecom_net_price_unit', { unit: props.product?.sales_um || t('ecom_pck') }) }}</span>

          <Price
            v-if="finalPrice != null && typeof finalPrice === 'number' && isLoggedIn"
            :value="finalPrice"
            class="block font-bold text-primary"
          />

          <Price
            v-if="regularPrice != null && typeof regularPrice === 'number' && !isLoggedIn"
            :value="regularPrice"
            class="block font-bold text-primary"
          />
        </template>

        <M2RedirectToContactPageButton
          v-else
          :sku="props.product?.sku ?? ''"
        />
      </div>
    </div>

    <M2AddToCartButton
      v-if="isLoggedIn"
      slim
      variant="primary"
      icon-right="arrow"
      :disabled="
        shouldItemBeDisabled({ product: props.product, quantity: productQuantityData?.min_sale_qty })
          || loadingItems.includes(props.product?.uid ?? '')
      "
      class="w-full !px-4"
      @click:add-to-cart="handleAddToCart"
    >
      {{ t('ecom_to_cart') }}
    </M2AddToCartButton>
  </div>
</template>

<script setup lang="ts">
import type { GetProductByCategoryIdQueryProductsItemsItem } from '@ecom/types/types'
import { useProductPrice } from '@ecom/composables/product/useProductPrice/useProductPrice'
import { useAppNav } from '@base/components/AppNav/useAppNav'
import { useWishlist } from '@customer/composables/useWishlist/useWishlist'
import useProductQuantity from '@ecom/composables/product/useProductQuantity'
import { useCart } from '@ecom/composables/checkout/useCart/useCart'
import { isPriceValid } from '@ecom/composables/product/useProductPrice/useProductPrice.helpers'

interface Props {
  product: GetProductByCategoryIdQueryProductsItemsItem
}
const props = defineProps<Props>()
const { callHook } = useNuxtApp()
const { isLoggedIn } = useCustomer()
const { t } = useI18n()
const { productLink } = useAppNav()
const { getProductFinalPrice, getProductRegularPrice, isOnPromotion } = useProductPrice()
const {
  wishlist,
  pending: wishlistPending,
  shouldItemBeDisabled: shouldWishlistItemBeDisabled,
  toggleWishlistItem,
  isItemInWishlist,
} = useWishlist()
const {
  addToCart,
  shouldItemBeDisabled,
  loadingItems,
} = useCart()
const { productQuantityData } = useProductQuantity(props.product)

const isItemWishlisted = ref<boolean>(false)

const isProductOnSpecialOffer = computed(() => {
  return isOnPromotion(props.product)
})

const finalPrice = computed(() => getProductFinalPrice(props.product))
const regularPrice = computed(() => getProductRegularPrice(props.product))
const isFinalPriceAssigned = computed(() => isPriceValid(finalPrice.value))
const isRegularPriceAssigned = computed(() => isPriceValid(finalPrice.value))

function getLinkTag(link?: string, tag?: string) {
  const tagHolder = tag || 'div'
  return link ? resolveComponent('nuxt-link') : tagHolder
}

watchEffect(() => {
  if (wishlist.value && props.product) {
    isItemWishlisted.value = isItemInWishlist(props.product)
  }
})

async function handleAddToWishlist(product: GetProductByCategoryIdQueryProductsItemsItem) {
  if (!product?.uid && !product?.sku && !product?.__typename) {
    return
  }

  if (product?.uid && product?.sku && product?.__typename) {
    await toggleWishlistItem(product)
    isItemWishlisted.value = Boolean(isItemInWishlist(product))
    callHook('analytics', {
      event: 'add_to_wishlist',
      ecommerce: {
        item: product.sku,
      },
    })
  }
}

async function handleAddToCart() {
  if (!props.product?.uid || !props.product?.sku) {
    return
  }

  await addToCart({ product: props.product, quantity: productQuantityData.value?.min_sale_qty || 1, refetchCart: false })
}
</script>
